<!-- System: STA
    Purpose: This compoment will display all 
            active companies companies where user
            can edit and archive company.
-->

<template>
  <v-col cols="12" sm="12" md="3">
    <!-- Company Card -->
    <v-card
      class="mx-auto my-10 company-boxes"
      max-width="300"
      height="360"
      elevation="10"
      @click="change_company(company)"
    >
      <!-- Company Image -->
      <img
        :src="imageCompany(company)"
        height="200"
        width="200"
        class="img-fluid proj-img mt-2"
        @error="
          $event.target.src = require('../../assets/images/dashboard_icons/no_image.svg')
        "
      />
      <!-- Company Name -->
      <h3 class="text-center">
        {{
          company.name.length < 30
            ? company.name
            : company.name.substring(0, 30) + "...."
        }}
      </h3>
      <!-- Company Details -->
      <v-card-text>
        <p class="font-weight-medium mt-n2">
          Projects :
          <span>{{ company.projects_count }}</span>
        </p>
        <p
          class="font-weight-medium mt-n2"
          v-if="company.user_status != 'employee'"
        >
          Members :
          <span v-if="company.employees_count">{{
            company.employees_count
          }}</span>
          <span v-else>1</span>
        </p>
        <p class="font-weight-medium mt-n2" v-else>
          Hours :
          <span>{{ min_to_hour(company.minutes) }}</span>
        </p>
      </v-card-text>
      <!-- Company Action -->
      <v-card-actions
        class="d-flex justify-center py-3 mt-n9"
        v-if="company.user_status !== 'employee'"
      >
        <!-- Archive Company Button -->
        <v-btn text>
          <v-icon color="primary" @click.stop="removeCompany(company)"
            >mdi-delete-forever</v-icon
          >
        </v-btn>
        <!-- Edit Company Button -->
        <v-btn text @click.stop="editCompany(company)">
          <v-icon color="primary">mdi-account-edit</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>


<script>
import { baseURL } from "@/assets/js/urls";
import { min_to_hour } from "@/assets/js/utils";
import { mapState } from "vuex";
export default {
  name: "Organization",
  components: {},
  props: ["company"],
  data: () => ({}),
  computed: {
    ...mapState("custom", ["selected_company", "backoffice_permission"]),
    url() {
      return baseURL.API_URL;
    },
  },
  mounted() {
    this.$store.dispatch("custom/getAllCompaniesList");
  },
  methods: {
    /**
     * This function is resposible for
     * returning the image of company
     */
    imageCompany(company) {
      if (company.logo != null) {
        return this.url + "storage/" + company.logo;
      } else {
        return require("../../assets/images/no_image.svg");
      }
    },
    /**
     * This function is resposible for
     * sending custom event to parent for
     * trigger remove company dialog and
     * pass data
     */
    removeCompany(company_id) {
      this.$emit("remove_company", company_id);
    },
    /**
     * This function is resposible for
     * for converstion of minutes to
     * hour
     */
    min_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hrs = time.hrs;
      let mins = time.mins;
      if (hrs <= 9) {
        hrs = "0" + time.hrs;
      }
      if (mins <= 9) {
        mins = "0" + time.mins;
      }
      return hrs + ":" + mins;
    },
    /**
     * This function is resposible for
     * sending custom event edit company
     * modal and pass data
     */
    editCompany(company) {
      this.$root.$emit("company_data", company);
    },
    /**
     * This function is resposible for
     * change the selected company
     */
    change_company(company) {
      if (
        this.backoffice_permission.companyName == company.name &&
        !this.backoffice_permission.companyPermission
      ) {
        this.$root.$emit("snack_bar", {
          show: true,
          message:
            "You're not allowed to step into this company, please contact your employer!",
          snackbarColor: "red",
        });
        return;
      }
      const channel = new BroadcastChannel("company-broadcast-channel");
      channel.postMessage(company.id);
      let empty_projects = {
        active: [],
        archived: [],
      };
      this.$store.commit("custom/set_projects_list", empty_projects);
      this.$store.commit("custom/set_team_members_project_list", []);
      this.$store.commit("custom/set_last_working_day", null);
      this.$store.commit("custom/set_archived_team_members_list", []);
      this.$store.commit("custom/set_active_team_members_list", []);
      this.$store.commit("custom/store_socket_employee", []);
      this.$store.commit("custom/set_selected_company", company);
      this.$store.commit("custom/set_lead_team_members", []);
      this.$store.commit("custom/set_team_members_list", []);
      this.$store.dispatch("custom/getAllCompaniesList");
      this.$store.commit("custom/set_team_members", []);
      this.$store.commit("custom/set_departments", []);
      this.$store.commit("custom/set_company_department_list", []);
      this.$store.commit("custom/set_project_task_list", []);
      this.$store.commit("custom/set_work_diary_team_members", []);
      this.$store.commit("custom/set_last_working_day", "");
      this.$store.dispatch("custom/last_company_record", company.id);
      this.$router.push({
        name: "Dashboard",
        params: { id: company.id },
      });
    },
  },
};
</script>

<style scoped>
.company-boxes {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, 0.1);
}
</style>
