import { render, staticRenderFns } from "./ActiveCompany.vue?vue&type=template&id=4d96f624&scoped=true"
import script from "./ActiveCompany.vue?vue&type=script&lang=js"
export * from "./ActiveCompany.vue?vue&type=script&lang=js"
import style0 from "./ActiveCompany.vue?vue&type=style&index=0&id=4d96f624&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d96f624",
  null
  
)

export default component.exports